/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */
@keyframes bounce {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(10px);
  }
}

.hero-section::before {
  content: "";
  background-image: linear-gradient(180deg, rgba(12, 19, 66, 0) 50%, #0c1342 100%);
  @apply absolute z-10 top-0 left-0 right-0 bottom-0;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .hero-section__description {
    margin-top: 40vh;
  }
}

.hero-section__slide-arrow:hover {
  animation-name: bounce;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.vimeo-wrapper {
  top: 0;
  left: 0;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
}

.vimeo-wrapper iframe {
  width: 100vw;
  height: 56.25vw;
  /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh;
  /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.info-section .grid {
  display: -ms-grid;
  display: grid;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .info-section .grid {
    -ms-grid-rows: 1fr;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template: "a  b c";
  }
}

@screen lg {
  .info-section .grid .info-section__box:nth-child(1) {
    @apply mt-0;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .info-section .grid .info-section__box:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: a;
  }
}

@screen lg {
  .info-section .grid .info-section__box:nth-child(2) {
    @apply mt-32;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .info-section .grid .info-section__box:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    grid-area: b;
  }
}

@screen lg {
  .info-section .grid .info-section__box:nth-child(3) {
    @apply mt-64;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .info-section .grid .info-section__box:nth-child(3) {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
    grid-area: c;
  }
}

.info-section .lines.inview-active .line-1 {
  transition: all 0.5s linear;
  transition-delay: 0.6s;
  @apply w-2/4;
}

.info-section .lines.inview-active .line-2 {
  transition: all 0.5s linear;
  transition-delay: 0.8s;
  @apply w-2/5;
}

.info-section .lines.inview-active .line-3 {
  transition: all 0.5s linear;
  transition-delay: 1s;
  @apply w-2/6;
}

.info-section-single .lines.inview-active .line-1 {
  transition: all 0.5s linear;
  transition-delay: 0.6s;
  @apply w-2/4;
}

.info-section-single .lines.inview-active .line-2 {
  transition: all 0.5s linear;
  transition-delay: 0.8s;
  @apply w-2/5;
}

.info-section-single .lines.inview-active .line-3 {
  transition: all 0.5s linear;
  transition-delay: 1s;
  @apply w-2/6;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .footer__wrapper {
    display: flex;
    justify-content: space-between;
  }
}

.footer__item a {
  position: relative;
}

.footer__item a::before {
  content: "";
  @apply absolute top-0 left-0 right-0 bottom-0 w-0;
}

.footer__item a:hover::before {
  height: 2px;
  top: calc(100% + 2px);
  @apply bg-white w-full;
  transition: width 0.2s ease-in;
}

.btn {
  @apply py-2 px-10 rounded-full bg-blue-500 tracking-widest;
  max-width: max-content;
  transition: all 0.1s linear;
  outline: none;
}

.btn.inview-active {
  transition-delay: 0s !important;
}

@screen sm {
  .btn {
    @apply text-xl;
  }
}

.btn:focus {
  outline: none;
}

.btn-blue {
  @apply bg-blue-500;
}

.btn-blue:hover {
  @apply bg-peach;
}

.info-section-single::before {
  content: "";
  background-image: linear-gradient(180deg, #0C1342, rgba(0, 0, 0, 0.5));
  @apply absolute z-0 top-0 left-0 right-0 bottom-0;
}

@tailwind utilities;
@font-face {
  font-family: 'Barlow';
  src: url("/assets/fonts/Barlow-Regular.woff2") format("woff2"), url("/assets/fonts/Barlow-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url("/assets/fonts/Barlow-Medium.woff2") format("woff2"), url("/assets/fonts/Barlow-Medium.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url("/assets/fonts/Barlow-Bold.woff2") format("woff2"), url("/assets/fonts/Barlow-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

.inview-animate--fade {
  opacity: 0;
  @apply duration-500 ease-in-out;
}

.inview-animate--fade.inview-active {
  opacity: 1;
}

.inview-animate--fade.inview-active:nth-child(1) {
  transition-delay: 0.25s;
}

.inview-animate--fade.inview-active:nth-child(2) {
  transition-delay: 0.5s;
}

.inview-animate--fade.inview-active:nth-child(3) {
  transition-delay: 0.75s;
}

.inview-animate--fade.inview-active:nth-child(4) {
  transition-delay: 1s;
}
