/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */

@import 'parts/modules/index';
@import 'parts/layout/index';

@tailwind utilities;

@import 'parts/utilities/index';
