//==============================================
// Layout/footer
//============================================== 

.footer {

    &__wrapper {
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            display: flex;
            justify-content: space-between;
        }
    }

    &__item {
        a {
            $self : &;
            position: relative;
            
            &::before {
                content: "";
                @apply absolute top-0 left-0 right-0 bottom-0 w-0;
            }

            &:hover {
                &::before {
                    height: 2px;
                    top: calc(100% + 2px);
                    @apply bg-white w-full;
                    transition: width 0.2s ease-in;
                }
            }
        }
    }
}