.btn {
    @apply  py-2 px-10 rounded-full bg-blue-500 tracking-widest;    
    max-width: max-content;
    transition: all 0.1s linear;
    outline: none;

    &.inview-active {
        transition-delay: 0s !important;
    }

    @screen sm {
        @apply text-xl;
    }

    &:focus {
        outline: none;
    }

    &-blue {
        @apply bg-blue-500;
    }
    
    &-blue:hover {
        @apply bg-peach;
    }    
}

.info-section-single {
    &::before {
        content: "";
        background-image: linear-gradient(180deg, #0C1342, rgba(0,0,0,.5));
        @apply absolute z-0 top-0 left-0 right-0 bottom-0;
    }
}
