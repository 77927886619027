//==============================================
// Modules/Hero Section
//==============================================


@keyframes bounce {
  
    from {
       transform: translateY(0);  
    }
   
    to {
      transform: translateY(10px);  
    }
  }

.hero-section {
    &::before {
        content: "";
        background-image: linear-gradient(180deg, rgba(12, 19, 66, 0) 50%, #0c1342 100%);
        @apply absolute z-10 top-0 left-0 right-0 bottom-0;
    }

    &__description {
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            margin-top: 40vh;
        }

    }

    &__slide-arrow {
        &:hover {
            animation-name: bounce;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-direction: alternate;
        }
    }
}

.vimeo-wrapper {
   top: 0;
   left: 0;
   height: 100%;
   z-index: -1;
   pointer-events: none;
   overflow: hidden;
}
.vimeo-wrapper iframe {
   width: 100vw;
   height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
   min-height: 100vh;
   min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}