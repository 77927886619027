//==============================================
// Modules/Info Section
//==============================================

.info-section {
    .grid {
        display:-ms-grid;
        display: grid;
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                -ms-grid-rows: 1fr;
                -ms-grid-columns: 1fr 1fr 1fr;
                grid-template: "a  b c";
            }

            .info-section__box  {
                &:nth-child(1) {
                    @screen lg {
                        @apply mt-0;
                    }
                    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        -ms-grid-row: 1;
                        -ms-grid-column: 1;
                        grid-area: a;
                    }
                }

                &:nth-child(2) {
                    @screen lg {
                        @apply mt-32;
                    }
                    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        -ms-grid-row: 1;
                        -ms-grid-column: 2;
                        grid-area: b;
                    }
                }

                &:nth-child(3) {
                    @screen lg {
                        @apply mt-64;
                    }
                    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        -ms-grid-row: 1;
                        -ms-grid-column: 3;
                        grid-area: c;
                    }
                    
                }
            }
    }

    .lines {
        &.inview-active {
            .line-1 {
                transition: all 0.5s linear;
                transition-delay: 0.6s;
                @apply w-2/4;
            }
            .line-2 {
                transition: all 0.5s linear;
                transition-delay: 0.8s;
                @apply w-2/5;
            }

            .line-3 {
                transition: all 0.5s linear;
                transition-delay: 1s;
                @apply w-2/6;
            }
        }
    }

    &-single {
        .lines {
            &.inview-active {
                .line-1 {
                    transition: all 0.5s linear;
                    transition-delay: 0.6s;
                    @apply w-2/4;
                }
                .line-2 {
                    transition: all 0.5s linear;
                    transition-delay: 0.8s;
                    @apply w-2/5;
                }
    
                .line-3 {
                    transition: all 0.5s linear;
                    transition-delay: 1s;
                    @apply w-2/6;
                }
            }
        }
    }
}